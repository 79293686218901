import React from 'react'
import '../style.css'
const WhySection = () => {
  return (
    <section id='why-section'>
        <div className="container">
            <h3 className='why-title'>Kenapa harus kuliah di Canadian College ?</h3>
            <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-3">
                    <div className="box-benefit">
                    <i className="fa-solid fa-graduation-cap"></i>
                    <h5 className='title'>Study</h5>
                    <p>Complete your Certificate or Diploma and get the credentials needed to start your career.</p>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-3">
                    <div className="box-benefit">
                    <i className="fa-solid fa-briefcase"></i>
                    <h5 className='title'>Work</h5>
                    <p>Students are able to receive paid work in Vancouver while studying at Canadian College.</p>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-3">
                    <div className="box-benefit">
                    <i className="fa-solid fa-utensils"></i>
                    <h5 className='title'>Eat</h5>
                    <p>Eat at eh!, Canadian College's very own on-campus restaurant.</p>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-3">
                    <div className="box-benefit">
                    <i className="fa-solid fa-bed"></i>
                    <h5 className='title'>Sleep</h5>
                    <p>Live in our student residence on Robson street, just a 15 minute walk to the Canadian College campus</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default WhySection