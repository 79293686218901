import React from 'react'
import '../style.css'
import Fade from 'react-reveal/Fade';

const Programs = () => {
  return (
    <section id='program'>
        <div className="container">
            <h3 className='title'>Programs</h3>
            <div className="program-list">
                <div className="row list">
                    <Fade left>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="box-program">
                            <i className="fa-solid fa-building"></i>
                            <hr />
                            <h5>Business</h5>
                        </div>
                    </div>
                    </Fade>
                    <Fade left>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="box-program odd">
                            <i className="fa-solid fa-bell-concierge"></i>
                            <hr className='odd' />
                            <h5>Hospitality</h5>
                        </div>
                    </div>
                    </Fade>
                    <Fade left>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="box-program">
                            <i className="fa-solid fa-globe"></i>
                            <hr />
                            <h5>International Trade</h5>
                        </div>
                    </div>
                    </Fade>
                    <Fade right>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="box-program odd">
                            <i className="fa-solid fa-user-gear"></i>
                            <hr className='odd' />
                            <h5>Project Management</h5>
                        </div>
                    </div>
                    </Fade>
                    <Fade right>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="box-program">
                            <i className="fa-solid fa-computer"></i>
                            <hr />
                            <h5>IT</h5>
                        </div>
                    </div>
                    </Fade>
                    <Fade right>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="box-program odd">
                            <i className="fa-solid fa-icons"></i>
                            <hr className='odd' />
                            <h5>Social Media</h5>
                        </div>
                    </div>
                    </Fade>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Programs