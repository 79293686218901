import React from 'react'

const FormValidation = (values) => {
    let errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.name) {
        errors.name = "Nama Lengkap Wajib Diisi"
    }
    if (!values.phone) {
        errors.phone = "No Telepon Wajib Diisi"
    }
    if (!values.code_kelas) {
        errors.code_kelas = "Kelas Wajib Dipilih"
    }
    if (!values.code_jenjang) {
        errors.code_jenjang = "Jenjang Pendidikan Wajib Dipilih"
    }
    if (!values.province_id) {
        errors.province_id = "Provinsi Wajib Wajib Dipilih"
    }
    if (!values.kabupaten_id) {
        errors.kabupaten_id = "Kabupaten/Kota Wajib Dipilih"
    }
    // if (!values.sekolah) {
    //     errors.sekolah = "Sekolah/Kampus Wajib Diisi"
    // }
    // if (!values.atribut) {
    //     errors.atribut = "Keterangan Wajib Diisi"
    // }
    if (!values.email) {
      errors.email = "Email Wajib diisi";
    } else if (!regex.test(values.email)) {
      errors.email = "Email Invallid";
    }
    
    return errors;
}

export default FormValidation