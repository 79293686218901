import React from 'react'
import '../style.css'

const Header = () => {
  return (
  <>
    <section id='header'>
        <div className="hero">
          <div className="container">
            <h1>Study & Work <br />in Canada</h1>
            <p>Business, Hospitality, International trade, Project Management, IT, and Social Media</p>
          </div>
        </div>
    </section>
  </>
  )
}

export default Header