import React from 'react'
import '../style.css'

import Fade from 'react-reveal/Fade';

const AboutUs = () => {
  return (
    <section id='about-us'>
        <img src="/img/campus.jpg" alt="" />
        <div className="about-us">
            <h5 className='title'>About Us</h5>
            <Fade>
            <p>Located in the centre of downtown Vancouver, Canadian College is onAlberni street alongside high-fashion retailers, top restaurants and famous hotels. Our campus includes classrooms, wh! restaurant, offices and a sunny rooftop patio. We also have an off-campus student residence within walking distance.</p>
            </Fade>
            <a href="https://canadiancollege.com/id/vancouver-campus" target="_blank" rel="noopener noreferrer">
              <button className='btn'>More On Our History</button>
            </a>
        </div>  
    </section>
  )
}

export default AboutUs