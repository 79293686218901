import React, { useEffect, useState } from 'react'
import axios from 'axios'
import FormValidation from '../service/FormValidation'
import Select from 'react-select'
import Swal from 'sweetalert2'

const FormRegister = () => {
    const [propinsi, setPropinsi] = useState([]);
    const [kabupaten, setKabupaten] = useState([]);
    const [sekolah, setSekolah] = useState([])
    const [jenjang, setJenjangPen] = useState([]);
    const [kelasSemester, setKelasSemester] = useState([])
    const [kelasSmtFilter, setKelasSmtFilter] = useState([])
    const [renderSekolah, setRenderSekolah] = useState(false);
    const [renderKet, setRenderKet] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false)
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({
        name:"",
        phone:"",
        email:"",
        province_id:"",
        kabupaten_id:"",
        code_jenjang:"",
        code_kelas:"",
        sekolah:"",
        atribut:"",
        id_konten: "69"
    })
    const [formError, setFormError] = useState({})
    let usia = [];
    for (let i = 10; i < 40; i++){
        usia.push(i)
    }
    const api = {
        url: "http://103.41.205.87:80/api/v1/application/", //dev
        url2: "http://103.41.205.87/", //dev
        url3: "https://superstudent.niecindonesia.com/api/v1/application/", //prod
        url4: "https://superstudent.niecindonesia.com/", //prod
        url5: "",
        key: 'koibg3kyOxX73kKTNwCsbNXnocy6nMPQ',
        eventKey: "eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ"
    };

    const handleChange = (e) => {
        const {name, value} = e.target
        setData({...data, [name]:value})
    }

    const handleSekolah = (id_propinsi) => {
        axios.get(api.url3 + 'sekolah-indonesia/' + id_propinsi)
        .then(res => setSekolah(res.data.data))
        .catch(err => console.log(err))
    }

    const loadKabKota = (e) => {
        const {name, value} = e.target
        handleSekolah(value)
        setData({...data, [name]: value})

        axios.get(api.url3 + 'master/kabupaten/' + value)
        .then(res => setKabupaten(res.data.data))
        .catch(err => console.log(err))
    }

    const setJenjang = (e) => {
        const {name, value} = e.target
        setData({...data, [name]:value})

        if (value === '') {
            setRenderKet(false)
            setRenderSekolah(false)  
        }
        else if (value === 'JP004') {
            setRenderKet(true)
            setRenderSekolah(false)
        } 
        else {
            const temp = Object.values(kelasSemester)
            const id = value
            const filterKlsSmtr = temp.filter(dataFilter => {
                return dataFilter.code_jenjang === id
            })
            setKelasSmtFilter(filterKlsSmtr)
            setRenderKet(false)
            setRenderSekolah(true)
        }
    }

    const submitForm = () => {
        setLoading(true);
        axios({
            method: 'POST',
            url: api.url4 + 'event/register',
            data: data,
            headers: {
                Authorization: `Bearer ${api.eventKey}`
            }
        })
        .then(res=>{  
            Swal.fire({
                title: 'Terima Kasih Sudah Registrasi',
                icon: 'success',
                confirmButtonText: 'Ok'
              }).then((result)=>{
                if (result.isConfirmed) {
                    setLoading(false)
                    window.location.reload(false)
                }
            }) 
        })
        .catch(err=>console.log(err))
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        setFormError(FormValidation(data))
        setIsSubmit(true)
    }

    const handleChangeSekolah = (selectedOption) => {
        setData({...data, sekolah: selectedOption.kode_sekolah})
    }

    useEffect(()=>{
        if (Object.keys(formError).length === 0 && isSubmit) {
            submitForm();
        }
    },[formError])

    useEffect(()=>{
        getDataPropinsi()
        getDataJenjang()
        getKelasSemester()
    },[])

    const getDataPropinsi = () => {
        axios.get(api.url3 + 'master/provinces')
        .then(res => setPropinsi(res.data.data))
        .catch(err => console.log(err))
    }

    const getDataJenjang = () => {
        axios.get(api.url3 + 'master/jenjang',{ 'headers': { Authorization: `Bearer ${api.key}` } })
        .then(res => setJenjangPen(res.data.data))
        .catch(err => console.log(err))
    }
    const getKelasSemester = () => {
        axios.get(api.url3 + 'master/kelas',{ 'headers': { Authorization: `Bearer ${api.key}` } })
        .then(res => setKelasSemester(res.data.data))
        .catch(err => console.log(err))
    }

    // console.log(propinsi);
    // console.log(kabupaten);
    // console.log(kabupatenFilter);
        
  return (
    <form action="" onSubmit={handleSubmit}>
        <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="form-input">
            <input name='name'value={data.name} className={`form-control ${formError.name && "is-invalid"}`} type="text" placeholder="Nama Lengkap" aria-label="default input example" onChange={handleChange}/>
            { formError.name && (<div className="invalid-feedback mb-3">{formError.name}</div>)}
            </div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="form-input">
                <input name='email' value={data.email} className={`form-control ${formError.email && "is-invalid"}`} type="email" placeholder="Email" aria-label="default input example" onChange={handleChange}/>
                { formError.email && (<div className="invalid-feedback mb-3">{formError.email}</div>)}
            </div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="form-input">
                <input name='phone' value={data.phone} className={`form-control ${formError.phone && "is-invalid"}`} type="number" placeholder="Telepon" aria-label="default input example" onChange={handleChange}/>
                { formError.phone && (<div className="invalid-feedback mb-3">{formError.phone}</div>)}
            </div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="form-input">
                <select name='province_id' value={data.province_id} className={`form-select ${formError.province_id && "is-invalid"}`} aria-label="Default select example" onChange={loadKabKota}>
                <option value={''}>--Provinsi--</option>
                {propinsi?.map(prov=>{
                    return(<option key={prov.id} value={prov.id}>{prov.province_name}</option>)
                })}
                </select>
                { formError.province_id && (<div className="invalid-feedback mb-3">{formError.province_id}</div>)}
            </div>
        </div>
        <div className="col-lg126 col-md-12 col-sm-12">
            <div className="form-input">
                <select name='kabupaten_id' value={data.kabupaten_id} className={`form-select ${formError.kabupaten_id && "is-invalid"}`} aria-label="Default select example" onChange={handleChange}>
                <option value={''}>--Kabupaten/Kota--</option>
                {kabupaten?.map(kab=>{
                    return(<option key={kab.id} value={kab.id}>{kab.kabupaten_name}</option>)
                })}
                </select>
                { formError.kabupaten_id && (<div className="invalid-feedback mb-3">{formError.kabupaten_id}</div>)}
            </div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="form-input">
                <select name='code_jenjang' value={data.code_jenjang} className={`form-select ${formError.code_jenjang && "is-invalid"}`} aria-label="Default select example" onChange={setJenjang}>
                <option value={''}>--Jenjang Pendidikan--</option>
                {jenjang?.map(jen=>{
                    return(<option key={jen.code_jenjang} value={jen.code_jenjang}>{jen.jenjang_pendidikan}</option>)
                })}
                </select>
                { formError.code_jenjang && (<div className="invalid-feedback mb-3">{formError.code_jenjang}</div>)}
            </div>
        </div>
        { renderSekolah && 
        (   <>
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="form-input">
                    <select name='code_kelas' value={data.code_kelas} className={`form-select ${formError.code_kelas && "is-invalid"}`} aria-label="Default select example" onChange={handleChange}>
                    <option value={''}>--Kelas/Semester--</option>
                    {kelasSmtFilter?.map(kls=>{
                        return(<option key={kls.code} value={kls.code}>{kls.name}</option>)
                    })}
                    </select>
                    { formError.code_kelas && (<div className="invalid-feedback mb-3">{formError.code_kelas}</div>)}
                </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="form-input">
                    <Select
                    options={sekolah}
                    getOptionLabel={(options) => options['nama_sekolah']}
                    getOptionValue={(options) => options['kode_sekolah']}
                    name='sekolah' onChange={handleChangeSekolah} />
                </div>
            </div>
            </>
        )}
        {
            renderKet && 
            (
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="form-input">
                    <input name='atribut' type="textarea" className={`form-select ${formError.ket_lainnya && "is-invalid"}`} placeholder='Keterangan' />
                    { formError.ket_lainnya && (<div className="invalid-feedback mb-3">{formError.ket_lainnya}</div>)}
                </div>
            </div>
            )
        }
        </div>
        <button type='submit' className={`btn ${loading && 'disabled'}`}>{loading ? 'Submit...' : 'Submit'}</button>
    </form>
  )
}

export default FormRegister