import React from 'react'
import '../style.css'
const Consultation = () => {
  return (
    <section id='consultation'>
        <div className="container">
            <div className="row justify-content-between">
                <div className="col-lg-8">
                    <div className="content1">
                        <h3>Free consultation with exceptional quality</h3>
                        <p>Just one call away: <a href="tel:+8411022703">+84 1102 2703</a></p>
                    </div>
                </div>
                <div className="col-lg-4 my-auto">
                    <div className="content2">
                        <a href="https://canadiancollege.com/id/contact" target="_blank">
                            <button className='btn'>Get your Consultation</button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Consultation